@mixin flex-align-size($w: auto, $jc: flex-end, $alignItem: center, $fd: initial, $mt: 0, $ml: 0, $mr: 0) {
    display: flex;
    justify-content: $jc;
    align-items: $alignItem;

    @if $w !=auto {
        width: $w;
    }

    @if $fd !=initial {
        flex-direction: $fd;
    }

    @if $mt !=0 {
        margin-top: $mt;
    }

    @if $ml !=0 {
        margin-left: $ml;
    }

    @if $mr !=0 {
        margin-right: $mr;
    }
}