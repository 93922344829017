.text-gray {
    color: $text-gray;
}

.text-center {
    text-align: center !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}