.flex-row-ml-space-around {
    @include flex-align-size($w: auto, $jc: space-around, $alignItem: initial, $fd: row, $mt: 0, $ml: auto, $mr: 0)
}

.flex-row-mt-flex-end {
    @include flex-align-size(auto, $jc: flex-end, $alignItem: initial, $fd: row, $mt: 1rem, $ml: 0, $mr: 0)
}

.flex-mt-flex-end-center-fullwidth {
    @include flex-align-size(100%, $jc: flex-end, $alignItem: center, $fd: initial, $mt: .5rem, $ml: 0, $mr: 0)
}

.flex-row-mt {
    @include flex-align-size(auto, $jc: initial, $alignItem: initial, $fd: row, $mt: 2rem, $ml: 0, $mr: 0)
}

.flex-mr-center-flex-end {
    @include flex-align-size(6rem, $jc: center, $alignItem: flex-end, $fd: initial, $mt: 0, $ml: 0, $mr: 1rem)
}