.btnlike-link {
    &:hover {
        text-decoration: none !important;
    }
}

.appnav-link {
    text-decoration: none;
    text-transform: capitalize;

    &.active {
        font-weight: bold;
    }
}