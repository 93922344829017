@media all and (max-width: 768px) {
    .appnavbar-disappear {
        display: none;
    }
}

@media all and (min-width: 768px) {
    button.mobilehamburger-disappear {
        display: none;
    }
}