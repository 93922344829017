.h-46px {
    height: 46px;
}

.ml-1rem {
    margin-left: 1rem !important;
}

.mt-1rem {
    margin-top: 1rem !important;
}

.my-1rem {
    margin: 1rem 0 !important;
}