body {
    margin: 0 auto;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.logo-50px {
    width: 50px;
    height: 50px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.panel-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(160, 160, 160, 0.2);
    height: 100vh;
}

.general-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 !important;
    justify-content: center;
    // z-index: 1301;
    z-index: 2000;
}

.float-right {
    float: right;
}

div.SingleDatePickerInput>div {
    width: 138px;
}

.general-formCtrl {
    margin: 0 1rem !important;
    width: 160px;

    >label {
        font-size: 21px;
        // transform: translate(0, 15px) scale(1);
    }
}

.general-badge {
    >span {
        transform: scale(1) translate(146%, -186%);
    }
}